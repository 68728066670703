<template>
  <section class="published-issues">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-heading">
            <h6><b>Published Issues</b></h6>
          </div>
        </div>
        <!-- i am going to temporarily assign current issue as the static asset   -->

        <div class="latest-issue">
          <h6>Sampriti Latest Issue</h6>

          <div
            class="item"
            v-for="(v, index) in latestIssue"
            :key="index"
            @click="toggleModalStatus(v.id)"
          >
            <img :src="v.issueImage" @click="toggleModalStatus(v.id)" />
            <div class="content">
              <h4>{{ v.title }}</h4>
              <button class="btn" @click="toggleModalStatus(v.id)">
                Read/Download
              </button>
            </div>
          </div>
        </div>

        <div class="grid-container">
          <div class="item" v-for="(v, index) in issueList" :key="index">
            <img :src="v.issueImage" @click="toggleModalStatus(v.id)" />
            <div class="down-content">
              <h4>{{ v.title }}</h4>
              <button class="btn" @click="toggleModalStatus(v.id)">
                Read/Download
              </button>
            </div>
          </div>
        </div>
        <div class="custom-modal" v-show="showModal">
          <div class="cancel-btn">
            <button type="submit" @click="cancelModel">cancel</button>
          </div>

          <div class="pdf-display">
            <iframe :src="selectedPdf"></iframe>
            <!-- <a :href="selectedPdf" target="_blank"></a> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- //need to use the suspense feature of vue  -->
  <div class="loading" v-if="isLoading">
    <p>Please Wait....</p>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
// import { sampritiIssueStore } from "@/stores/upload-new-issue.js";
import { onMounted } from "@vue/runtime-core";
export default {
  name: "published-issues",

  setup() {
    //store instance
    // const sampritiIssues = sampritiIssueStore();

    //issue list
    const issueList = ref([]);

    const isLoading = ref(false);

    const latestIssue = ref([]);

    onMounted(() => {
      isLoading.value = true;

      // sampritiIssues.getPdfsFromFirebase().then(() => {
      //   issueList.value = sampritiIssues.sampritiIssueCollection;

      //   issueList.value.forEach((v) => {
      //     if (v.title === "Sampriti Latest Issue") {
      //       latestIssue.value = [];
      //       latestIssue.value.push(v);
      //     }
      //   });

      //   // console.log(latestIssue.value);

      //   // isLoading.value = !true;
      // });

      //latest issue PDF for temporary solution
      var latest = ref({
        id: "18",
        title: "Sampriti latest Issue",
        issueImage: require("@/assets/images/x-2.jpeg"),
        issuePDF: require("@/assets/pdfs/Vol X-II.pdf"),
      });
      // console.log(latest);
      latestIssue.value.push(latest.value);
      isLoading.value = !true;

      // creating all the issues of the issueList
      issueList.value = [
        {
          id: "1",
          title: "Sampriti Vol-I, Issue-I",
          issueImage: require("@/assets/images/i-1.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol I-I.pdf"),
        },
        {
          id: "2",
          title: "Sampriti Vol-II, Issue-I",
          issueImage: require("@/assets/images/ii-1.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol II-I.pdf"),
        },
        {
          id: "3",
          title: "Sampriti Vol-II, Issue-II",
          issueImage: require("@/assets/images/ii-2.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol II-II.pdf"),
        },
        {
          id: "4",
          title: "Sampriti Vol-III, Issue-I",
          issueImage: require("@/assets/images/iii-1.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol III-I.pdf"),
        },
        {
          id: "5",
          title: "Sampriti Vol-III, Issue-II",
          issueImage: require("@/assets/images/iii-2.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol III-II.pdf"),
        },
        {
          id: "6",
          title: "Sampriti Vol-IV, Issue-I",
          issueImage: require("@/assets/images/iv-1.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol IV-I.pdf"),
        },
        {
          id: "7",
          title: "Sampriti Vol-IV, Issue-II",
          issueImage: require("@/assets/images/iv-2.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol IV-II.pdf"),
        },
        {
          id: "8",
          title: "Sampriti Vol-V, Issue-I",
          issueImage: require("@/assets/images/v-2.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol V-I.pdf"),
        },
        {
          id: "9",
          title: "Sampriti Vol-V, Issue-II",
          issueImage: require("@/assets/images/v-2.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol V-II.pdf"),
        },
        // {
        //   id: "9",
        //   title: "Sampriti Vol-VI, Issue-I",
        //   issueImage: require("@/assets/images/vi-1.jpeg"),
        //   issuePDF: require("@/assets/pdfs/Vol VI-II.pdf"),
        // },
        {
          id: "10",
          title: "Sampriti Vol-VI, Issue-II",
          issueImage: require("@/assets/images/vi-2.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol VI-II.pdf"),
        },
        {
          id: "11",
          title: "Sampriti Vol-VII, Issue-I",
          issueImage: require("@/assets/images/vii-1.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol VII-I.pdf"),
        },
        {
          id: "12",
          title: "Sampriti Vol-VII, Issue-II",
          issueImage: require("@/assets/images/vii-2.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol VII-II.pdf"),
        },
        {
          id: "13",
          title: "Sampriti Vol-VIII, Issue-I",
          issueImage: require("@/assets/images/vii-2.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol VIII-I.pdf"),
        },
        {
          id: "14",
          title: "Sampriti Vol-VIII, Issue-II",
          issueImage: require("@/assets/images/vii-2.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol VIII-II.pdf"),
        },
        {
          id: "15",
          title: "Sampriti Vol-IX, Issue-I",
          issueImage: require("@/assets/images/ix-1.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol IX-I.pdf"),
        },
        {
          id: "16",
          title: "Sampriti Vol-IX, Issue-II",
          issueImage: require("@/assets/images/ix-1.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol IX-II.pdf"),
        },
        {
          id: "17",
          title: "Sampriti Vol-X, Issue-I",
          issueImage: require("@/assets/images/x-1.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol X-I.pdf"),
        },
        {
          id: "18",
          title: "Sampriti Vol-X, Issue-II",
          issueImage: require("@/assets/images/x-2.jpeg"),
          issuePDF: require("@/assets/pdfs/Vol X-II.pdf"),
        },
      ];
    });

    //selected pdf
    const selectedPdf = ref("");

    const showModal = ref(false);

    function toggleModalStatus(id) {
      // var target = sampritiIssues.sampritiIssueCollection.filter(
      //   (x) => x.id === id
      // );

      issueList.value.find((x) => x.id === id);
      // var target = issueList.value.filter((x) => x.id === id);
      var target = issueList.value.find((x) => x.id === id);
      // console.log("THIS IS ", target.issuePDF.default);

      selectedPdf.value = target.issuePDF.default;
      console.log(selectedPdf.value);

      showModal.value = true;
    }

    function cancelModel() {
      showModal.value = false;
    }

    return {
      showModal,
      cancelModel,
      selectedPdf,
      toggleModalStatus,
      issueList,
      isLoading,
      latestIssue,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin makeModalFixed {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.published-issues {
  height: 100%;
  min-height: 100vh;
  width: 100%;

  background-color: #101f2c;

  padding: 130px auto;

  @media (max-width: 576px) {
    padding: 40px auto;
  }

  .section-heading {
    h6 {
      height: 12px;
      color: white;
      letter-spacing: 0.15px;
      text-transform: uppercase;
      margin: 35px auto;

      @media (max-width: 576px) {
        margin: 20px auto;
      }
    }
  }

  .latest-issue {
    height: auto;
    max-height: 450px;
    width: 100%;
    // background: yellow;
    margin-bottom: 20px;
    border-bottom: 1px solid white;

    h6 {
      font-size: 14px;
      font-weight: 700;
      color: white;
      margin: 15px 0px;
    }

    .item {
      height: 100%;
      max-height: auto;
      width: 50%;

      transition: all 350ms ease-in;
      &:hover {
        transform: translateY(-1px) scaleY(1);
      }

      img {
        height: 80%;
        max-height: 250px;
        width: 100%;
        cursor: pointer;
        border-radius: 6px;
      }
      .content {
        // max-height:70px;
        h4 {
          font-size: 12px;
          color: white;
          margin: 12px 0px;
          cursor: pointer;
          &:hover {
            color: goldenrod;
          }
        }

        button {
          background-color: blue;
          color: white;
          font-size: 10px;
          padding: 4px 12px;
          margin: 10px 10px;
          border-radius: 10px;
          border: none;
          outline: none;

          &:hover {
            background-color: black;
            color: white;
            transform: rotate(5deg);
            transition: all 350ms ease-in;
          }
        }
      }
    }
  }

  .custom-modal {
    @include makeModalFixed();
    background-color: rgba($color: black, $alpha: 0.5);

    .cancel-btn {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        height: 22px;
        width: 100px;
        background-color: red;
        padding: 3px 25px;
        font-size: 11px;
        color: white;
        border-radius: 9px;
        margin: 15px auto;
        border: none;
        outline: none;
      }
    }

    .pdf-display {
      height: 88%;
      width: 90%;
      margin: 10px auto;

      @media (max-width: 576px) {
        width: 97%;
      }

      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 10px;
    margin-bottom: 25px;
    .item {
      border: 1px solid white;
      border-radius: 10px;

      cursor: pointer;

      img {
        height: 80%;
        border-radius: 10px;
      }

      .down-content {
        height: 20%;
        text-align: center;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        h4 {
          font-size: 12px;
          font-weight: 600;

          &:hover {
            color: goldenrod;
          }
        }

        .btn {
          background-color: blue;
          color: white;
          font-size: 10px;
          padding: 4px 12px;
          margin: auto 10px;
          border-radius: 10px;
          border: none;
          outline: none;

          &:hover {
            background-color: black;
            color: white;
            transform: rotate(5deg);
            transition: all 350ms ease-in;
          }
        }
      }

      &:hover {
        transform: translateY(-2px) scaleY(1.01);

        transition: all 350ms ease-in;
      }
    }
  }
}
.loading {
  position: fixed;
  inset: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 1000;

  display: grid;
  place-content: center;

  p {
    color: white;
    font-size: 15px;
  }
}
</style>
