<template>
  <section class="readLatestSection" id="readLatestSectionId">
    <div class="readLatest">
      <div class="banner">
        <div class="heading">
          <h6>CURRENT ISSUE</h6>
        </div>
        <div class="current-issue-image" id="imageHolderDiv">
          <div class="read-now-modal">
            <button
              @click="toggleCurrentIssueView()"
              class="read-now"
              id="readNow"
            >
              Read now
            </button>

            <div class="read-pdf-now" id="readPdfNow">
              <!-- <iframe src="http://www.pdf995.com/samples/pdf.pdf"></iframe> -->
              <iframe :src="latestPdf"></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="read-latest">
        <div class="button-area">
          <button @click="togglePdfView()" id="btn">Read-Latest</button>
        </div>

        <div class="read-pdf" id="readPdf">
          <!-- <iframe src="http://www.pdf995.com/samples/pdf.pdf"></iframe> -->
          <iframe :src="latestPdf"></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
// import { sampritiIssueStore } from "@/stores/upload-new-issue.js";

export default {
  name: "read-latest",

  setup() {
    //latestIssuePDf
    const latestPdf = ref("");

    //store instance
    // const sampritiIssues = sampritiIssueStore();

    onMounted(() => {
      //latest issue PDF for temporary solution
      var latest = ref({
        id: "1000",
        title: "Sampriti latest Issue",
        issueImage: require("@/assets/images/x-2.jpeg"),
        issuePDF: require("@/assets/pdfs/Vol X-II.pdf"),
      });

      // if (sampritiIssues.latestIssue.length !== 0) {
      //   latestPdf.value = sampritiIssues.latestIssue[0].issuePDF[0];
      //   insertIssueimage(sampritiIssues.latestIssue[0].issueImage[0]);
      // } else {
      //   sampritiIssues.getPdfsFromFirebase().then(() => {
      //     const allCollection = sampritiIssues.sampritiIssueCollection;

      //     allCollection.forEach((v) => {
      //       if (v.title === "Sampriti Latest Issue") {
      //         insertIssueimage(v.issueImage[0]);
      //         latestPdf.value = v.issuePDF[0];
      //       }
      //     });
      //   });
      // }

      latestPdf.value = latest.value.issuePDF.default;

      // console.log(latestPdf.value);
      insertIssueimage(latest.value.issueImage);
    });

    function insertIssueimage(img) {
      var url = `url(${img})`;
      var imageHolderDiv = document.getElementById("imageHolderDiv");

      imageHolderDiv.style.backgroundImage = url;
      imageHolderDiv.style.backgroundSize = "contain"; // Make sure the image covers the entire div
      imageHolderDiv.style.backgroundPosition = "center"; // Center the image in the div
      // imageHolderDiv.style.backgroundRepeat = "no-repeat"; // Prevent the image from repeating
    }

    const isPdfVsisble = ref(false);
    const isCurrentIssueVisible = ref(false);

    function togglePdfView() {
      var getPdfDiv = document.getElementById("readPdf");
      var getBtn = document.getElementById("btn");

      if (!isPdfVsisble.value) {
        getPdfDiv.style.display = "block";
        getBtn.innerText = "Hide-pdf";
        isPdfVsisble.value = true;
      } else {
        getPdfDiv.style.display = "none";
        getBtn.innerText = "Read-Latest";

        isPdfVsisble.value = false;
      }
    }
    function toggleCurrentIssueView() {
      var getPdfDiv = document.getElementById("readPdfNow");
      var getBtn = document.getElementById("readNow");

      if (!isCurrentIssueVisible.value) {
        getPdfDiv.style.display = "block";
        getBtn.innerText = "Hide-pdf";
        isCurrentIssueVisible.value = true;
      } else {
        getPdfDiv.style.display = "none";
        getBtn.innerText = "Read-Latest";

        isCurrentIssueVisible.value = false;
      }
    }
    return {
      togglePdfView,
      latestPdf,
      isPdfVsisble,
      toggleCurrentIssueView,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin makeFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.readLatestSection {
  padding-top: 145px;
  width: 100%;
  background-color: #172640;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.readLatest {
  height: 500px;
  width: 100%;
  background-color: transparent;
  // border-top: 1px solid black;
  display: flex;
  padding: 10px;
  margin-top: 30px;
  -webkit-box-shadow: -10px -3px 23px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -10px -3px 23px -14px rgba(0, 0, 0, 0.75);
  box-shadow: -10px -3px 23px -14px rgba(0, 0, 0, 0.75);

  @media (max-width: 768px) {
    height: 340px;
  }

  @media (max-height: 552px) and (min-width: 452px) {
    margin-top: 140px;
  }

  .banner {
    flex: 2;
    // background-color: white;
    display: flex;
    flex-direction: column;

    @media (max-width: 964px) {
      flex: 1;
    }

    .heading {
      flex: 2;

      @include makeFlex();

      justify-content: flex-start;
      padding-left: 4em;

      h6 {
        font-size: 1rem;
        color: white;
        line-height: 30px;
        font-weight: 600;

        text-transform: capitalize;
      }

      @media (max-width: 552px) {
        padding-left: 2em;
        // font-size: 14px;

        h6 {
          font-size: 14px;
        }
      }
    }

    .current-issue-image {
      flex: 12;
      border-radius: 10px;
      cursor: pointer;
      // background-image: url("@/assets/images/sampriti_banner.jpg");
      background-repeat: no-repeat no-repeat;
      background-size: cover;
      background-position: center -60px;
      margin: 2px;

      &:hover {
        transform: translateY(-3.5px);
        transition: all 250ms ease-in-out;
      }

      @media (max-width: 768px) {
        background-repeat: no-repeat no-repeat;
        background-size: contain;
        margin: 2px;
        background-position: center center;
      }

      @media (max-width: 668px) {
        background-size: cover;
        background-position: center -50px;
      }

      .read-now-modal {
        background-color: transparent;
        height: 100%;
        width: 100%;

        // background: rgba(255, 255, 255, 0.2);
        @include makeFlex();

        flex-direction: column;

        @media (min-width: 669px) {
          display: none;
        }

        .read-now {
          padding: 6px 14px;
          font-size: 10px;
          font-weight: 700;
          margin: 4px auto;
          letter-spacing: 0.4px;
          backdrop-filter: blur(16px) saturate(180%);
          -webkit-backdrop-filter: blur(16px) saturate(180%);
          background-color: rgba(255, 255, 255, 0.75);
          border-radius: 12px;
          border: 1px solid black;
          background-color: transparent;
          color: black;

          &:hover {
            background-color: black;
            color: white;
            transform: rotate(360deg);
            transition: all 350ms ease-in;
          }
        }

        .read-pdf-now {
          display: none;
          height: 90%;
          width: 100%;

          iframe {
            height: 100%;
            width: 100%;
            padding: 4px;
          }
        }
      }
    }
  }

  .read-latest {
    flex: 1;
    @include makeFlex();
    flex-direction: column;

    @media (max-width: 668px) {
      display: none;
    }

    .button-area {
      height: 15%;
      width: 100%;
      // background-color: yellow;
      @include makeFlex();

      button {
        padding: 10px 25px;
        backdrop-filter: blur(16px) saturate(180%);
        -webkit-backdrop-filter: blur(16px) saturate(180%);
        background-color: rgba(255, 255, 255, 0.75);
        border-radius: 20px;
        border: 1px solid rgba(209, 213, 219, 0.3);
        font-size: 12px;
        font-weight: 600;

        &:hover {
          transform: translate3d(-2px, -2px, -1px);
          transition: transform 250ms ease-in-out;
          color: gold;
          background-color: black;
        }

        @media (max-width: 964px) {
          font-size: 10px;
          font-weight: 500;
          padding: 7px 22px;
          margin-bottom: 0;
        }
      }
    }

    .read-pdf {
      display: none;
      width: 100%;
      height: 85%;
      margin-left: 10px;
      object-fit: cover;

      iframe {
        width: 100%;
        height: 100%;
      }

      @media (max-width: 964px) {
        object-fit: cover;
        padding: 0;

        // height: 75%;
        iframe {
          width: 100%;
          height: 85%;
          padding: 0;
        }
      }

      @media (max-width: 768px) {
        object-fit: cover;
        padding: 0;

        iframe {
          width: 100%;
          height: 100%;
          padding: 0;
        }
      }
    }
  }
}
</style>
